<template>
  <div class="slider" v-scrollanimation :style="{ top: isMobile ? step : 0 }">
    <div class="bottom-to-top">
      <div class="slider__track flex" ref="sliderTrack" :style="innerStyles">
        <div
          class="slider__item slider__item_gradient"
          :style="{
            background: `linear-gradient(180deg, ${item.color}, #ffffff)`,
          }"
          :class="{
            active: index == activeIndex + 1,
            'in-active': index != activeIndex + 1,
            pending: index == activeIndex + 2,
            leaving: index == activeIndex,
          }"
          v-for="(item, index) in cards"
          :key="item.id"
        >
          <div class="slider__inner">
            <div class="slider__head head flex align-items-center">
              <div
                class="head__icon flex f-center"
                :style="{ background: item.color }"
              >
                <img
                  :src="require(`@/assets/icons/home-slider/${item.icon}`)"
                  :alt="item.id"
                />
              </div>
              <div class="head__indicator">
                <div class="current-line"></div>
              </div>
            </div>
            <h3 class="slider__title headline-3 w700">
              {{ item.title }}
            </h3>
            <p class="slider__text caption-3">
              {{ item.text }}
            </p>
            <a
              href="#"
              class="slider__link flex align-items-center"
              :style="{ color: item.color }"
              ><span>Начать сейчас</span>
              <svg
                width="1.5625rem"
                height="1.5625rem"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.3181 5.84961L19.5532 12.5004L13.3181 19.1511L12.4632 18.3496L17.3975 13.0863H6.25V11.9144H17.3975L12.4632 6.6511L13.3181 5.84961Z"
                  :fill="item.color"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount } from "vue";

const activeIndex = ref(0);

const sliderTrack = ref(null);

const duration = 10000;

let interval;

const isMobile = ref();

const handleResize = () => {
  isMobile.value = window.innerWidth <= 475;
};

if (typeof window != undefined) {
  isMobile.value = window.innerWidth <= 475;
  window.addEventListener("resize", handleResize);
}
const cards = ref([
  {
    id: 1,
    title: "Быстрые Бюджетные решения",
    text: "Соберите свой сайт исходя из своих потребностей и узнайте цену ",
    color: "var(--primary-2)",
    icon: "lightning.svg",
  },
  {
    id: 2,
    title: " Большой проект, большая ответственность",
    text: "Мы поможем создать Ваш собственный уникальный продукт",
    color: "var(--primary-4)",
    icon: "product.svg",
  },
  {
    id: 3,
    title: "Каталог услуг",
    text: "Мы имеем широкий спектр услуг по разработке, Мы уверенны вы найдете подходящее решение",
    color: "var(--primary-1)",
    icon: "stack.svg",
  },
]);
const innerStyles = ref({});
let step = "";
let transitioning = false;

const setStep = () => {
  const innerWidth = sliderTrack.value.scrollWidth;
  const innerHeight = sliderTrack.value.scrollHeight;
  const totalCards = cards.value.length;
  console.log(innerWidth);
  console.log(totalCards);

  step = `${(isMobile.value ? innerHeight : innerWidth) / totalCards}px`;
  console.log(step);
};

const next = () => {
  if (transitioning) return;
  transitioning = true;
  afterTransition(() => {
    console.log("next");
    const card = cards.value.shift();
    cards.value.push(card);
    resetTranslate();
    transitioning = false;
  });
  moveLeft();
};

// const prev = () => {
//   if (transitioning) return;
//   transitioning = true;
//   console.log("prev");
//   afterTransition(() => {
//     const card = cards.value.pop();
//     cards.value.unshift(card);
//     resetTranslate();
//     transitioning = false;
//   });
//   moveRight();
// };

const moveLeft = () => {
  innerStyles.value = {
    transform: isMobile.value
      ? `translateY(-${step})
                    translateY(-${step})`
      : `translateX(-${step})
                    translateX(-${step})`,
  };
};
// const moveRight = () => {
//   innerStyles.value = {
//     transform: isMobile.value
//       ? `translateX(-${step})
//                     translateX(${step})`
//       : `translateX(-${step})
//                     translateX(-${step})`,
//   };
// };

const afterTransition = (callback) => {
  const listener = () => {
    callback();
    sliderTrack.value.removeEventListener("transitionend", listener);
  };
  sliderTrack.value.addEventListener("transitionend", listener);
};

const resetTranslate = () => {
  console.log(innerStyles.value);
  innerStyles.value = {
    transition: "none",
    transform: `translateX(-${isMobile.value ? 0 : step}) translateY(-${
      isMobile.value ? step : 0
    })`,
  };
};

onMounted(() => {
  interval = setInterval(() => {
    next();
  }, duration);

  setStep();
  resetTranslate();
  window.addEventListener("resize", setStep);
});

onBeforeMount(() => {
  window.removeEventListener("resize", setStep);
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>
<style lang="scss" scoped>
.slider {
  width: 100%;
  height: max-content;
  position: relative;

  &__track {
    gap: 1.5rem;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &__inner {
    padding: 1.125rem;
    border-radius: 1rem;
    position: relative;
    z-index: 2;
  }

  &__link {
    color: var(--primary-2);
    font-size: 1.125em;

    svg {
      margin-left: 0.5rem;
    }
  }

  &__text {
    margin-bottom: 1.25rem;
    margin-top: 0.5rem;
  }

  &__title {
    line-height: 2rem;
  }

  &__item {
    flex: 0 0 calc(50% - 1.5rem);
    border-radius: 1rem;
    position: relative;
    transition: all 1s ease;
    bottom: 0;
    left: 0;
    width: calc(50% - 1.5rem);

    &_gradient {
      padding: 0.0625rem;
      background: linear-gradient(180deg, var(--primary-2), #ffffff);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0.0625rem;
      left: 0.0625rem;
      right: 0.0625rem;
      bottom: 0.0625rem;
      z-index: 1;
      border-radius: 1rem;
      background: var(--blue-1);
    }

    &::before {
      content: "";
      z-index: 2;
      display: block;
      position: absolute;
      top: 0.0625rem;
      left: 0.0625rem;
      right: 0.0625rem;
      bottom: 0.0625rem;
      border-radius: 1rem;
      background: rgba(255, 255, 255, 0.1);
    }

    .head {
      margin-bottom: 1.25rem;

      &__icon {
        width: 3.125rem;
        min-width: 3.125rem;
        height: 3.125rem;
        background: var(--primary-2);
        border-radius: 0.75rem;
        margin-right: 0.625rem;
        padding: 0.4rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__indicator {
        width: 100%;
        height: 0.0625rem;
        background: #ffffff50;
        border-radius: 50%;
        position: relative;
        transition: all 0.3s ease;

        .current-line {
          width: 0;
          height: 0.1875rem;
          background: var(--white);
          border-radius: 0.3125rem;
          position: absolute;
          left: 0;
          top: -0.0625rem;
          transition: all 9s linear;
        }
      }
    }

    &.active {
      opacity: 1;
      .head__indicator {
        .current-line {
          width: 100%;
        }
      }
    }

    &.in-active {
      width: calc(50% - 1.5rem);
      opacity: 0;
      pointer-events: none;

      &.leaving {
        right: null;
        left: -50%;
        opacity: 0;
      }

      &.pending {
        opacity: 0.5;
        pointer-events: none;
      }

      .head__indicator {
        opacity: 0;
        .current-line {
          transition: all 0.3s ease;
          transition-delay: 0.5s;
          width: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .slider {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .slider {
    font-size: 12px;
  }
}

@media screen and (max-width: 475px) {
  .slider {
    font-size: 14px;

    &__track {
      flex-direction: column;
    }

    &__item {
      width: 100%;

      &.in-active {
        width: 100%;

        &.leaving {
          right: 0;
          left: 0;
          top: -50%;
          opacity: 0;
        }
      }
    }
  }
}
</style>
