<template>
  <div class="services" v-scrollanimation>
    <div class="container">
      <div class="services__inner">
        <h2 class="services__title headline-2 w700 left-to-right">
          {{ other ? "Другие услуги" : "Наши Услуги" }}
        </h2>
        <div class="services__wrap flex f-space-between">
          <router-link
            class="services__item bottom-to-top"
            v-for="(item, index) in servicesList"
            :class="`delay-${index + 3 * index}`"
            :key="index"
            :to="$i18nRoute({ name: item.routeName })"
          >
            <div class="services__card">
              <img :src="item.imgPath" class="img" alt="services" />
            </div>
            <h3 class="services__subtitle headline-3 w700">
              {{ item.subtitle }}
            </h3>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ServicesImg1 from "@/assets/images/services/services_img_1.png";
import ServicesImg2 from "@/assets/images/services/services_img_2.png";
import ServicesImg3 from "@/assets/images/services/services_img_3.png";
import ServicesImg4 from "@/assets/images/services/services_img_4.png";
import { defineProps } from "vue";

const servicesList = [
  {
    subtitle: "Создание Веб Сайтов",
    imgPath: ServicesImg1,
    routeName: "Development",
  },
  {
    subtitle: "Создание Мобильных Приложений",
    imgPath: ServicesImg2,
    routeName: "Application",
  },
  {
    subtitle: "Приложения на тв",
    imgPath: ServicesImg3,
    routeName: "Development",
  },
  {
    subtitle: "Реклама в СМИ",
    imgPath: ServicesImg4,
    routeName: "Development",
  },
];

defineProps({
  other: Boolean,
});
</script>

<style lang="scss" scoped>
.services {
  position: relative;
  overflow: hidden;

  &__title {
    margin-bottom: 3.4375rem;
  }

  &__inner {
    padding: 5rem 0;
  }

  &__item {
    width: 100%;
    display: block;

    &:hover {
      .services {
        &__card {
          background: var(--secondary-1);
        }

        &__subtitle {
          opacity: 1;
        }
      }
    }
  }

  &__wrap {
    width: 100%;
    gap: 2.5rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__subtitle {
    transition: all 0.3s ease;
    opacity: 0.5;
  }

  &__card {
    width: 100%;
    padding: 3.125rem 1.5rem;
    background: #ffffff10;
    width: max-content;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;

    .img {
      width: 13.125rem;
      height: 13.125rem;
    }
  }
}

@media screen and (max-width: 1336px) {
  .services {
    &__subtitle {
      font-size: 1.125rem;
    }

    &__wrap {
      gap: 1.5rem;
    }

    &__card {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 2.5rem 1rem;
      .img {
        max-width: 10rem;
        max-height: 10rem;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .services {
    &__title {
      margin-bottom: 2rem;
    }

    &__inner {
      padding: 4rem 0;
      // padding-top: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .services {
    &__wrap {
      overflow: hidden;
      overflow-x: auto;
    }

    &__item {
      min-width: 13rem;
    }
  }
}
@media screen and (max-width: 576px) {
  .services {
    &__inner {
      padding: 3rem 0;
    }
  }
}
</style>
