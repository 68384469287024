<template>
  <div class="partners" v-scrollanimation>
    <div class="container">
      <div class="partners__inner">
        <h2 class="partners__title headline-2 w700 left-to-right delay-3">
          Клиенты И Партнеры
        </h2>
        <div class="partners__wrap">
          <div
            class="partners__item flex f-center bottom-to-top"
            v-for="(item, index) in [1, 3, 4, 5, 23]"
            :class="`delay-${index + 3}`"
            :key="item"
          >
            <svg
              width="128"
              height="28"
              viewBox="0 0 128 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M43.0998 26.6386L44.989 20.8343H54.174L56.0632 26.6386H61.8303L53.0554 1.18408H46.1201L37.3328 26.6386H43.0998ZM46.3562 16.6333L49.4883 7.00084H49.6872L52.8193 16.6333H46.3562Z"
                fill="white"
              />
              <path
                d="M64.3046 26.6386H69.5993V15.1791C69.5993 13.0662 70.9417 11.6617 72.7066 11.6617C74.4466 11.6617 75.615 12.8549 75.615 14.7317V26.6386H80.7481V14.9802C80.7481 13.004 81.8792 11.6617 83.8057 11.6617C85.496 11.6617 86.7638 12.7182 86.7638 14.8435V26.6386H92.0461V13.7995C92.0461 9.66064 89.5851 7.29914 86.0304 7.29914C83.2339 7.29914 81.0589 8.72847 80.2758 10.916H80.077C79.4679 8.70361 77.5166 7.29914 74.8941 7.29914C72.3213 7.29914 70.3699 8.66633 69.5745 10.916H69.3508V7.54772H64.3046V26.6386Z"
                fill="white"
              />
              <path
                d="M104.439 27.0115C109.162 27.0115 112.344 24.7121 113.089 21.1699L108.192 20.8467C107.658 22.3009 106.291 23.0591 104.526 23.0591C101.878 23.0591 100.2 21.3066 100.2 18.4604V18.4479H113.201V16.9937C113.201 10.5058 109.274 7.29914 104.227 7.29914C98.6095 7.29914 94.9678 11.2888 94.9678 17.1802C94.9678 23.2331 98.5598 27.0115 104.439 27.0115ZM100.2 15.1667C100.312 12.9916 101.965 11.2516 104.314 11.2516C106.614 11.2516 108.205 12.8922 108.217 15.1667H100.2Z"
                fill="white"
              />
              <path
                d="M116.166 26.6386H121.461V15.8378C121.461 13.4888 123.176 11.873 125.513 11.873C126.246 11.873 127.253 11.9973 127.75 12.1589V7.46071C127.278 7.34885 126.619 7.27428 126.085 7.27428C123.947 7.27428 122.194 8.51718 121.498 10.8787H121.3V7.54772H116.166V26.6386Z"
                fill="white"
              />
              <path
                d="M32.3276 0.593394C33.123 0.27519 33.9242 1.03934 33.6439 1.84897L25.0899 26.5612C24.8869 27.1478 24.204 27.4096 23.6609 27.1091L1.3246 14.7534C0.586689 14.3452 0.654277 13.2631 1.43725 12.9499L32.3276 0.593394Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.partners {
  position: relative;

  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    margin-bottom: 4.375rem;
  }

  &__wrap {
    width: 100%;
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    width: 100%;
    border: 0.0625rem solid var(--gray-1);
    border-radius: 1.5rem;
    height: 7.2rem;
    padding: 0 1rem;

    svg {
      width: 8rem;
    }
  }
}

@media screen and (max-width: 1336px) {
  .partners {
    &__inner {
      padding: 5rem 0;
    }

    &__item {
      padding: 0 2rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .partners {
    &__wrap {
      gap: 1.5rem;
    }

    &__title {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .partners {
    &__item {
      min-width: 10rem;
      height: 6rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .partners {
    &__wrap {
      grid-template-columns: repeat(3, 1fr);
    }

    &__inner {
      padding: 3rem 0;
    }
  }
}

@media screen and (max-width: 475px) {
  .partners {
    &__wrap {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      width: 100%;
    }
  }
}
</style>
