<template>
  <div class="portfolio" v-scrollanimation>
    <div class="container">
      <div class="portfolio__inner" :class="{ pd: isPortfolioPage }">
        <div
          class="portfolio__head flex align-items-center f-space-between"
          :class="{ column: isGrid || isOther }"
        >
          <h2
            class="portfolio__title headline-2 w700 left-to-right"
            v-if="!isPortfolioPage"
          >
            {{ isOther ? "Другие работы" : "Портфолио" }}
          </h2>
          <div class="filters flex" v-if="tags">
            <div
              class="right-to-left"
              v-for="(tag, index) in tags"
              :class="[`delay-${index + 2 * index}`]"
              :key="index"
              @click="selectTag(tag?.id)"
            >
              <div
                class="filters__item"
                :class="{ active: selectedTags.indexOf(tag?.id) != -1 }"
              >
                <span class="caption-3">{{ tag?.title }}</span>
              </div>
            </div>
          </div>
        </div>
        <Loader v-if="loading" />
        <transition-group
          name="list"
          tag="ul"
          class="portfolio__wrap"
          :class="{ grid: isGrid }"
        >
          <div
            v-for="(item, index) in isPortfolioPage
              ? selectedTags.length != 0
                ? sortedPortfolio
                : portfolio
              : selectedTags.length != 0
              ? sortedPortfolio.slice(0, 3)
              : portfolio.slice(0, 3)"
            :key="item.id"
          >
            <div
              class="portfolio__card flex bottom-to-top"
              :class="[`delay-${index + 4 * index}`, { column: isGrid }]"
            >
              <div class="portfolio__image">
                <img :src="item?.picture.medium" alt="" />
              </div>
              <div class="portfolio__desc">
                <h3 class="w700">{{ item?.title }}</h3>
                <p class="body-3 w400">
                  {{ item?.short_description }}
                </p>
                <router-link
                  :to="
                    $i18nRoute({ name: 'Detail', params: { slug: item?.slug } })
                  "
                  class="button-1 flex align-items-center"
                >
                  <span> Подробнее </span
                  ><svg
                    width="0.8125rem"
                    height="0.875rem"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.78537 0.615234L12.771 6.99995L6.78537 13.3847L5.96464 12.6152L10.7016 7.56245H0L0 6.43745H10.7016L5.96464 1.38467L6.78537 0.615234Z"
                      fill="#3772FF"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </transition-group>
        <div
          v-if="!isPortfolioPage"
          class="portfolio__button flex f-center bottom-to-top delay-10"
        >
          <router-link
            class="primary-button"
            :to="$i18nRoute({ name: 'Portfolio' })"
            >Смотреть все</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed, ref } from "vue";
import { useStore } from "vuex";
import Loader from "@/components/Loader.vue";

const store = useStore();

const loading = ref(true);

const selectedTags = ref([]);

const sortedPortfolio = ref([]);

store.dispatch("getPortfolio", {
  cb: () => {
    loading.value = false;
  },
});

store.dispatch("getTags", {
  cb: () => {
    loading.value = false;
  },
});

const selectTag = (tagId) => {
  let index = selectedTags.value.indexOf(tagId);

  if (index != -1) {
    selectedTags.value = selectedTags.value.filter((item) => item != tagId);
  } else {
    selectedTags.value = [...selectedTags.value, tagId];
  }

  filterList();
};

const filterList = () => {
  sortedPortfolio.value = portfolio.value.filter((item) => {
    let isSlected = [];

    selectedTags.value.forEach((tag) => {
      isSlected = [
        ...isSlected,
        ...item.tags.filter((itemTag) => itemTag == tag),
      ];
    });

    return isSlected.length != 0;
  });
};

const portfolio = computed(() => store.getters.portfolio);

const tags = computed(() => store.getters.tags);

defineProps({
  isGrid: Boolean,
  isPortfolioPage: Boolean,
  isOther: Boolean,
});
</script>
<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(6.25rem);
}

.portfolio {
  &__inner {
    padding: 6.25rem 0;
  }

  &__head {
    margin-bottom: 4.0625rem;

    &.column {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .portfolio__title {
        margin-bottom: 3.75rem;
      }
    }
  }

  .filters {
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: flex-end;

    &__item {
      padding: 0.25rem 0.75rem;
      background: var(--bg-3);
      border: 0.125rem solid transparent;
      border-radius: 1.25rem;
      cursor: pointer;
      transition: all 0.3s ease;

      span {
        color: var(--grey-3);
      }

      &:hover {
        border-color: var(--white);
      }

      &.active {
        background: var(--bg-2);
        border-color: var(--white);

        span {
          color: var(--white);
        }
      }
    }
  }

  &__card {
    width: 100%;
    gap: 2rem;
    margin-bottom: 2.5rem;

    &.column {
      gap: 1.25rem;
      flex-direction: column;
      margin-bottom: 0;

      .portfolio {
        &__image {
          img {
            height: 12.5rem;
            border-radius: 0.63rem;
          }
        }

        &__desc {
          p {
            margin-bottom: 0.5rem;
            font-size: 1em;
          }

          h3 {
            margin-bottom: 0.5rem;
            font-size: 1.13em;
          }

          a {
            font-size: 1.13em;
          }
        }
      }
    }
  }

  &__wrap {
    margin-bottom: 6.25rem;

    &.grid {
      display: grid;
      grid-column-gap: 2.5rem;
      grid-row-gap: 6.25rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
      height: 20rem;
      border-radius: 1rem;
    }
  }

  &__desc {
    width: 100%;

    h3 {
      font-weight: 700;
      font-size: 1.5em;
      line-height: 2rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: var(--grey-3);
      margin-bottom: 2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
    }

    a {
      color: var(--secondary-1);
      svg {
        margin-left: 0.625rem;
      }
    }
  }
}

@media screen and (max-width: 1336px) {
  .portfolio {
    &__inner {
      padding: 5rem 0;

      &.pd {
        padding-top: 2rem;
      }
    }

    .filters {
      justify-content: flex-start;
    }

    &__head {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 3rem;
    }

    &__title {
      margin-bottom: 2rem;
    }

    &__wrap {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .portfolio {
    &__image {
      img {
        height: 13rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .portfolio {
    &__wrap {
      &.grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .portfolio {
    &__title {
      margin-bottom: 1.5rem;
    }
    &__head {
      margin-bottom: 2rem;
    }
    &__inner {
      padding: 3rem 0;
    }
    .filters {
      width: 100%;
      flex-wrap: nowrap;
      font-size: 14px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        min-width: max-content;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .portfolio {
    &__wrap {
      display: grid;
      grid-column-gap: 2.5rem;
      grid-row-gap: 2rem;
      grid-template-columns: repeat(1, 1fr);

      &.grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__card {
      flex-direction: column;

      &.column {
        .portfolio {
          &__image {
            img {
              height: 20rem;
              border-radius: 0.63rem;
            }
          }
        }
      }
    }

    &__image {
      img {
        height: 20rem;
      }
    }

    &__desc {
      font-size: 15px;

      p {
        line-height: 2rem;
      }
    }
  }
}
</style>
